import React from 'react';
import Link from 'gatsby-link';

/**
 * Internal dependencies.
 */
import Nav from './nav';
import './_style.scss';
import MainLogo from '../../images/svg/lt-italia.svg';

const Header = ( { data } ) => {

	const { header: { siteTitle, siteTagLine }, headerMenuItems } = data.HWGraphQL;

	return (
		<header className="site-header-container pb-3 pt-5">
			<div className="site-header">
				<div className="site-brand">
					<Link to="/">
						<div className="site-brand__logo" alt="header logo" title={ siteTitle } tagline={ siteTagLine }>
							<MainLogo />
						</div>
					</Link>
					{/*
					<div>
						<h2 className="screen-reader-text site-brand__title">{ siteTitle }</h2>
						<p className="site-brand__description">{ siteTagLine }</p>
					</div>
					*/}
				</div>

				<Nav headerMenuItems={ headerMenuItems }/>
			</div>
		</header>
	);
};

/**
 *  Exporting Just the footer as well without static query for storybook,
 *  as static query does not work in storybook
 */
export { Header };

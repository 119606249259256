import Link from 'gatsby-link';
import { normalizePath, sanitize } from "../../utils/functions";
import PropTypes from "prop-types";
import React from "react";
import LtLogo from '../../images/svg/lt-italia.svg';
import ImitLogo from '../../images/footer/imit-logo.png';

const Footer = ( { data } ) => {
	const { footer: { socialLinks, sidebarOne, sidebarTwo }, footerMenuItems } = data.HWGraphQL;

	const staticSocialLink = [
		{ iconName: 'facebook', iconUrl: '#'  },
		{ iconName: 'twitter', iconUrl: '#'  },
		{ iconName: 'instagram', iconUrl: '#'  },
		{ iconName: 'linkedin', iconUrl: '#'  },
		{ iconName: 'youtube', iconUrl: '#'  },
	];

	const socialLinksData = socialLinks.length ? socialLinks : staticSocialLink;

	return (
		<footer className="footer">

			{/*Top section*/}
			<div className="footer__top">
				{
					footerMenuItems.edges.length ? (
						<ul className="menu-bottom">
							{ footerMenuItems.edges.map( menu  => (
								<li key={ menu.node.menuItemId }>
									<Link
										className="h1"
										to={ normalizePath( menu.node.url )  }
										activeClassName="active"
									>
										{ menu.node.label }
									</Link>
								</li>
							)) }
						</ul>
					) : ''
				}
			</div>


			{/*	Bottom section*/}
			<div className="footer__bottom">
				<div className="row">

					{ sidebarOne ? <div  dangerouslySetInnerHTML={ { __html: sanitize(sidebarOne) } } className="col-6 footer__sidebar-one footer-widget"/> : null }
					{ sidebarTwo ? <div  dangerouslySetInnerHTML={ { __html: sanitize(sidebarTwo) } } className="col-6 footer__sidebar-two footer-widget"/> : null }

					<div className="col-auto footer-logo">
						<LtLogo/>
					</div>

					<div className="copyright col-auto">
						<h6 className="titolo">LT Italia Luxury Trades</h6>
						Via Miniera, 18<br/>
						36073 Cornedo Vicentino VI / Italia<br/>
						Partita Iva : 03028650244
						<br/>
						<Link
							className="titolo policy"
							to={ '/cookie-policy/'  }
							activeClassName="active"
						>
							Cookie Policy
						</Link>
					</div>


					<div className="col-auto footer-logo">
						<img width="72" height="50" src={ImitLogo} alt="Imit Logo"/>
					</div>

					{
					socialLinksData.length ?
						<div className="social col-auto">
							<h6 className="titolo">Follow us</h6>
							<ul className="social-links">
								{ socialLinksData.map( socialLink => (
									<li key={ socialLink.iconName }>
										<a href={ socialLink.iconUrl } target="_blank" rel="noreferrer">
											{ /*	'facebook' === socialLink.iconName ? 'Facebook' : null }
											{ 'twitter' === socialLink.iconName ? 'Twitter' : null }
											{ 'instagram' === socialLink.iconName ? 'Instagram' : null */ }
											{ 'linkedin' === socialLink.iconName ? 'Linkedin' : null }
											{ /*	'youtube' === socialLink.iconName ? 'Youtube' : null */ }
										</a>
									</li>
								) ) }
							</ul>
						</div> :
					null
					}

				</div>
			</div>

		</footer>
	);
};

Footer.propTypes = {
	copyrightText: PropTypes.string,
};

Footer.defaultProps = {
	copyrightText: `Codeytek Academy ${ new Date().getFullYear() }`,
};

/**
 *  Exporting Just the footer as well without static query for storybook,
 *  as static query does not work in storybook
 */
export {
	Footer
};

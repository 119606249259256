/**
 * Layout component contains header and footer
 *
 * @package gatsby-wordpress-theme
 */

import React from "react"
import PropTypes from "prop-types"

import './../../fonts/fonts.css';
import './../../sass/theme.scss';

// import DevGrid from "../_dev/grid";
import Header from "../header";
import ContactForm from "../forms";
import Footer from "../footer";

const Layout = ( { children, data }) => {
  return (
    <>
      <Header data={ data }/>
  	    <main className="main-container">{children}</main>
        <ContactForm/>
  	  <Footer data={ data }/>
    </>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Layout

// https://medium.com/@ronnyangelofreites/connecting-contact-form-7-wordpress-plugin-with-gatsbyjs-1c68f472b0fd

import React, { useEffect, useState } from 'react'
import { useFormik } from 'formik'
import axios from 'axios'
import './_style.scss';

const ContactForm = () => {
  const WEBSITE_URL = 'https://wordpress-319227-1755611.cloudwaysapps.com';
  const FORM_ID = '17'; //Form id that provides Contact Form 7

  const [token, setToken] = useState('') // store token
  const [isSuccessMessage, setIsSuccessMessage] = useState(false) // manage is success message state
  const [messageSent, setMessageSent] = useState(false) // manage sent message state

  // this effect function authenticates our subcriber user to get a token
  useEffect(() => {
    axios({
      method: 'post',
      url: `${WEBSITE_URL}/wp-json/jwt-auth/v1/token`,
      data: {
        username: 'Lbcm27868bu12ecMS', // provide a user credential with subscriber role
        password: 'LbcmbuecMS*&QY%0Q0avm$cF'
      },
      headers: {
        'Content-Type': 'application/json'
      },
    }).then(response => {
      setToken(response.data.token)
    }).catch(error => console.error( 'Error', error ))
  }, [])

  // use useFormik hook using object destructuring assignment to extract helpful methods
  const {
    handleChange,
    isSubmitting,
    values,
    handleSubmit,
  } = useFormik({
    initialValues: {
      nome: '',
      cognome: '',
      email: '',
      telefono: '',
      privacy: '',
    },
    onSubmit: ({
      nome,
      cognome,
      email,
      telefono,
      privacy
    }, { setSubmitting, resetForm }) => {
      setSubmitting(true)
      // here we created a FormData field for each form field
      const bodyFormData = new FormData();
      bodyFormData.set('nome', nome);
      bodyFormData.set('cognome', cognome);
      bodyFormData.set('email', email);
      bodyFormData.set('telefono', telefono);
      bodyFormData.set('privacy', privacy);

      // here we sent
      axios({
        method: 'post',
        url: `${WEBSITE_URL}/wp-json/contact-form-7/v1/contact-forms/${FORM_ID}/feedback`,
        data: bodyFormData,
        headers: {
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'multipart/form-data'
        },
      }).then(response => {
        // actions taken when submission goes OK
        resetForm()
        setSubmitting(false)
        setMessageSent(true)
        setIsSuccessMessage(true)
      }).catch(error => {
        // actions taken when submission goes wrong
        setSubmitting(false)
        setMessageSent(true)
        setIsSuccessMessage(false)
      })
    },
  })

  useEffect(() => {
    // set timeout 3 seconds to remove error/success message.
    setTimeout(() => {
      // this will reset messageSent and isSuccessMessage state
      setMessageSent(false)
      setIsSuccessMessage(false)
    }, 10000);
    // this effect function will be dispatched when isSuccessMessage or messageSent changes its state
  }, [isSuccessMessage, messageSent])

  return (
    <section className="contatto">
      <h4 className="titolo col col-md-4">
        Contact us for more information
      </h4>
      <form className="col col-md-8" onSubmit={handleSubmit}>
        <fieldset>
          <div className="input-group">
            <input
              className="form-control"
              id="nome"
              name="nome"
              placeholder="Name*"
              type="text"
              onChange={handleChange}
              value={values.nome}
              required
            />
          </div>

          <div className="input-group">
            <input
              className="form-control"
              id="cognome"
              name="cognome"
              placeholder="Surname*"
              type="text"
              onChange={handleChange}
              value={values.cognome}
              required
            />
          </div>

          <div className="input-group">
            <input
              className="form-control"
              id="telefono"
              name="telefono"
              placeholder="Telephone"
              type="tel"
              onChange={handleChange}
              value={values.telefono}
            />
          </div>

          <div className="input-group">
            <input
              className="form-control"
              id="email"
              name="email"
              placeholder="Email"
              type="email"
              onChange={handleChange}
              value={values.email}
              required
            />
          </div>

          <div className="form-check">
            <input
              className="form-check-input"
              id="privacy"
              name="privacy"
              type="checkbox"
              onChange={handleChange}
              value={values.privacy}
              required
            />
            <small
              className="form-check-label"
              htmlFor="privacy"
              aria-label="Privacy policy">
              I accept the processing of personal data indicated in the <a href="/privacy-policy/" target="_blank">Privacy Policy</a> according to EU Reg. 2016/679
            </small>
          </div>

          <div className="input-group send-button">
            <button
              aria-label="Submit message"
              type="submit"
              disabled={isSubmitting}
              className="btn btn-primary btn-lg px-5"
            >Submit</button>
          </div>
        </fieldset>

        {
          messageSent && isSuccessMessage && (
          <div className="border border-success border-2 p-3">Thank you for your message. It has been sent.</div>
          )
        }
        {
          messageSent && !isSuccessMessage && (
          <div className="border border-danger border-2 p-3">There was an error trying to send your message. Please try again.</div>
          )
        }

      </form>
    </section>
  )
}

export default ContactForm
